import shared from "./shared";

export const environment = {
  ...shared,
  MINDLAB_FRONTEND_URL: "https://staging.mindlab.be",
  REACT_APP_ENV: "demo",
  REACT_APP_MATOMO_KEY: 3,
  REACT_APP_SERVER_URL: "https://api.demo.bloomup.org",
  REACT_APP_URL: "https://app.demo.bloomup.org",
};

export default environment;
